import { ColorModeScript } from '@chakra-ui/react';
import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client'


// GLOBAL STYLES
import './css/styles.css';
import './css/customChakra.css';


import ErrorBoundary from './workspace/ErrorBoundary';
import { LoadingCenter } from './components/LoadingCenter';
const App = lazy(() => import('./App'));

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container);


root.render(
  <React.StrictMode>
      <ErrorBoundary>
          <ColorModeScript />
    
          <React.Suspense fallback={<LoadingCenter />}>
              <App />
          </React.Suspense>
      </ErrorBoundary>
  </React.StrictMode>
);