import { Component, ErrorInfo, ReactNode } from 'react';

type Props = {
    children: ReactNode;
};

type State = {
    hasError: boolean;
    error: Error | null;
    errorInfo: ErrorInfo | null;
};

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
        };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state to indicate error
        return { hasError: true, error };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        // Log error information
        console.error('Error:', error);
        console.error('Error Info:', errorInfo);

        // Update state with error details
        this.setState({ error, errorInfo });
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{ padding: 20 }}>
                    <h2>Something went wrong.</h2>
                    <pre style={{ color: 'red' }}>
                        <strong>Error:</strong> {this.state.error?.message}
                    </pre>
                    <pre style={{ whiteSpace: 'pre-wrap', color: 'red' }}>
                        <strong>Stack Trace:</strong> {this.state.errorInfo?.componentStack}
                    </pre>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
